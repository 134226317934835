.navbar {
  background-color: #ffffff;
  box-shadow: 0px 4px 12px #21242940;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  padding: 0px 20px;
}

.navbar-menu {
  display: flex;
  align-items: center;
  gap: 24px;
}

.navbar .text-wrapper {
  color: var(--bwcolor-gray-100);
  font-family: var(--text-sm-bold-font-family);
  font-size: var(--text-sm-bold-font-size);
  font-style: var(--text-sm-bold-font-style);
  font-weight: var(--text-sm-bold-font-weight);
}

.navbar .logo {
  color: #274b6c;
  top: 8px;
  white-space: nowrap;
  font-size: 26px;
  font-weight: bold;
  height: 80px;
}

.navbar .logo img {
  width: 100%;
  height: 100%;
}

.navbar .user-menu {
  align-items: center;
  display: flex;
  text-align: center;
  border-radius: 50%;
  border-radius: 50%;
  padding: 14px;
  margin: 10px;
}

.navbar .text-wrapper-2 {
  color: var(--bwcolor-gray-80);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  height: 16px;
  left: 77px;
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  top: 34px;
  white-space: nowrap;
}

.navbar .foto {
  height: 40px;
  left: 25px;
  object-fit: cover;
  top: 12px;
  width: 40px;
}

.navbar .divider {
  background-color: var(--bwcolor-gray-20);
  height: 64px;
  left: 0;
  top: 0;
  width: 1px;
}
